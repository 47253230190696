import React from "react";
import { ReactNode } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";

export type PrincipleBoxProps = {
  index: number; // 1-based index, will show in FE
  title: string;
  content: ReactNode;
};

const PrincipleBox = ({ index, title, content }: PrincipleBoxProps) => {
  return (
    <Box>
      <Header>
        <HeaderSubtitle>Principle No. {index}</HeaderSubtitle>
        {title}
      </Header>

      <Content>{content}</Content>
    </Box>
  );
};

const Box = styled.div`
  background-color: ${colors.offWhite300};
  padding: 50px 25px;
`;

const Header = styled.h2`
  ${text.fullWidth.H5}
  font-weight: 600;
  color: ${colors.drePurple};
  margin-bottom: 20px;

  ${media.tablet} {
    ${text.tablet.H5};
    font-weight: 600;
  }
  ${media.mobile} {
    ${text.mobile.H5};
    font-weight: 600;
  }
`;

const HeaderSubtitle = styled.span`
  ${text.fullWidth.Body}
  color: #000;
  display: block;
  margin-bottom: 10px;

  ${media.tablet} {
    ${text.tablet.Body};
  }
  ${media.mobile} {
    ${text.mobile.Body};
  }
`;

const Content = styled.div`
  ${text.fullWidth.Body}
  letter-spacing: -0.02em;

  ${media.tablet} {
    ${text.tablet.Body};
  }
  ${media.mobile} {
    ${text.mobile.Body};
  }
`;

export default PrincipleBox;
